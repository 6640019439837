import BankIcon from "@/app/icons/bank.svg";
import AdminIcon from "@/app/icons/admin-services.svg";
import PaidIcon from "@/app/icons/paid.svg";
import LegalIcon from "@/app/icons/legal.svg";
import TranslationIcon from "@/app/icons/translate.svg";
import FileDeleteIcon from "@/app/icons/file-delete.svg";
import MultipleIcon from "@/app/icons/multiple.svg";

export const services = [
    {
        "title": "Multiple Services",
        "icon": MultipleIcon,
    },
    {
        "title": "Bank Account Opening",
        "icon": BankIcon,
    },
    {
        "title": "Administrative Services",
        "icon": AdminIcon,
    },
    {
        "title": "Accounting, Bookkeeping, etc...",
        "icon": PaidIcon,
    },
    {
        "title": "Legal and Compliance Services",
        "icon": LegalIcon,
    },
    {
        "title": "Translation and Attestation Services",
        "icon": TranslationIcon,
    },
    {
        "title": "Company Liquidation Services",
        "icon": FileDeleteIcon,
    },
    {
        "title": "Others",
    },
];