"use client";

import styles from "./index.module.scss";
import {services} from "./data";
import TextInput from "@/app/_components/Shared/TextInput";
import {useCallback, useState} from "react";
import MailGray from "@/app/icons/mail-gray.svg";
import UserIcon from "@/app/icons/user.svg";
import IconButton from "@/app/_components/Shared/IconButton";
import Image from "next/image";
import axios from "axios";
import { object, string } from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomSelect from "@/app/_components/Shared/CustomSelect";


const schema = object({
    name: string().required(),
    email: string().email().required(),
    message: string().required(),
    service: string().required(),
});

export default function ContactUs({
    select= false
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        "name": "",
        "email": "",
        "message": "",
        "service": services[0].title,
    });

    const handleChange = useCallback(e => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    }, [data]);
    const selectService = useCallback(service => {
        setData({
            ...data,
            service: service.title,
        });
    }, [data]);

    const submit = useCallback(async () => {
        setIsLoading(true);
        schema.validate(data).then(() => {
            return axios.post( process.env.NEXT_PUBLIC_API_URL + '/tickets', {
                name: data.name,
                email: data.email,
                message: `${data.message} \n\n Service: ${data.service}`,
            }).then(response => {
                toast.success('Message sent successfully. We will get back to you soon');
                return setData({
                    "name": "",
                    "email": "",
                    "message": "",
                    "service": services[0].title,
                })
            }).catch(error => {
                toast.error('Failed to send message. Please try again later');
            });
        }).catch(error => {
            toast.error(error.message);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [data])

    const onSelect = useCallback(service => {
        setData({
            ...data,
            service: service.value,
        });
    }, [data]);

    const allServices = services.map(service => ({label: service.title, value: service.title}));
    return (
        <div className={styles.container}>

            {
                select && <div className={styles.section}>
                    <CustomSelect
                        onChange={onSelect}
                        value={allServices.find(service => service.value === data.service)}
                        options={allServices}
                        instanceId="serviceSelect"
                        isSearchable={false}
                    />
                </div>
            }
            <div className={styles.section}>
                <TextInput placeholder="Your Name" name="name" required onChange={handleChange} icon={MailGray} />
                <TextInput type="email" placeholder="Email ID" name="email" required onChange={handleChange} icon={UserIcon} />
            </div>
            {
                !select && <div className={styles.servicesContainer}>
                    <h2>Select Service</h2>
                    <div className={styles.services}>
                        {
                            services.map((service, index) => (
                                <div key={index}
                                     className={`${styles.service} ${data.service === service.title && styles.active}`}
                                     onClick={() => selectService(service)}>
                                    {
                                        service.icon && <Image src={service.icon} alt="icon"/>
                                    }
                                    {service.title}
                                </div>
                            ))
                        }
                    </div>
                </div>
            }
            <div className={styles.section}>
                <TextInput type="textarea" placeholder="Your Message" name="message" required onChange={handleChange}/>
            </div>
            <div className={`${styles.section} ${styles.btnSection}`}>
                <IconButton onClick={submit} disabled={isLoading} isLoading={isLoading}>
                    Submit
                </IconButton>
            </div>
        </div>
    );
}
