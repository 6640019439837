"use client";
import LiteYouTubeEmbed from "react-lite-youtube-embed"
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css"
import styles from './index.module.scss';

export default function Youtube({
    id,
    aspectHeight,
    aspectWidth,
    title,
}) {
    return (
        <div className={styles.iframeContainer}>
            <LiteYouTubeEmbed
                aspectHeight={aspectHeight}
                aspectWidth={aspectWidth}
                id={id}
                title={title}
            />
        </div>
    );
}