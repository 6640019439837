"use client";

import styles from './index.module.scss';
import Image from "next/image";
import Link from 'next/link'
import Youtube from "@/app/_components/Shared/Youtube";

export default function Box({
    icon,
    iconAlt = "icon",
    title = null,
    description = null,
    buttonText = null,
    buttonLink = '#',
    className = null,
    youtube = null,
   }) {
    const onclick = (e) => {
        if (!buttonLink || buttonLink === '#') {
            e.preventDefault();
            return false;
        }
    }
    return (
        <Link href={buttonLink} onClick={onclick} className={`${styles.box} ${className}`}>
            {icon && <Image className={styles.icon} src={icon} alt={iconAlt} />}
            {title && <h2>{title}</h2>}
            {description && <p>{description}</p>}
            {buttonText && <Link href={buttonLink}>{buttonText}</Link>}
            {youtube &&
                <Youtube
                    id={youtube}
                    title="YouTube video player"
                />}
        </Link>
    );
}