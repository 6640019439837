import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/home/runner/work/ninjaz-landing/ninjaz-landing/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/HeroSection/index.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/OurServicesSection/index.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/AccountingNeedsSection/index.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/Shared/Box/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/Shared/ContactUs/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/Shared/IconButton/index.jsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/Shared/TextTitle/index.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/Shared/Services/index.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/Shared/Youtube/index.jsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/WhySection/index.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/TestimonialsSection/index.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/_components/ContactUsSection/index.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/apartment.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/bag.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/brain.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/check.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/compliance.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/fast.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/our-services-bank.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/our-services-book.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/our-services-remove.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/our-services-tax.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/our-services-visa.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/package.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/pencil.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/process.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/support.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/team.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/icons/tech.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/images/all-your-corporate-needs-in-one-place.png");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/images/company-setup-services.png");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/images/elite.jpg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/images/hero.png");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/images/purunity.png");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/images/udare.png");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ninjaz-landing/ninjaz-landing/src/app/index.module.scss");
