"use client";

import Select from 'react-select'
import styles from './index.module.scss';


export default function CustomSelect(props) {
    return (
        <Select styles={{
            control: (baseStyles, state) => ({
                ...baseStyles,
                padding: "24px",
                background: "white",
                border: "border: 2px solid #F4F4F4",
                borderRadius: "24px",
                color: "#222",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "26px",
            }),
        }} {...props} />
    );
}