"use client";

import styles from './index.module.scss';
import Image from "next/image";

export default function TextInput({
    icon,
    placeholder,
    type = "text",
    value,
    onChange,
    containerClassName = "",
    inputClassName = "",
    required = false,
    name,
   }) {
    return (
        <div className={`${styles.container} ${containerClassName}`}>
            {icon && <Image src={icon} alt="icon" />}
            {
                type === "textarea" ? (
                    <textarea name={name} required={required} value={value} onChange={onChange}
                              placeholder={placeholder} className={inputClassName}>{value}</textarea>
                ) : <input name={name} required={required} value={value} onChange={onChange} placeholder={placeholder}
                           type={type} className={inputClassName}/>
            }
        </div>
    );
}